<template>
  <div>
    <v-card v-for="purse in purses" :key="purse.id" class="pa-2 mt-1 card">
    <v-row class="d-flex align-start mt-2">
      <span class="medium-font ml-auto mr-3 mt-1" v-text="purse.Purse_Title"></span>
      <div class="mr-auto">
       <v-switch @change="purseUse(purse)" v-model="purse.use"></v-switch>
       </div>
    </v-row>
    <v-row class="py-1 d-flex justify-center align-center mt-2">
      <span class="medium-font mr-3 ml-auto">اعتبار قابل استفاده:</span>
      <span class="medium-font mr-auto ml-3" v-text="purse.balance_show"></span>
    </v-row>
    <v-row class="py-1 d-flex justify-center align-center my-3">
      <span class="medium-font mr-3 ml-auto">اعتبار استفاده شده:</span>
      <span class="medium-font mr-auto ml-3" v-text="purse.deducted"></span>
    </v-row>
    <v-row class="py-1 d-flex align-center mb-3">   
      <span class="medium-font mr-3 ml-auto">مانده اعتبار:</span>
      <span class="medium-font mr-auto ml-3" v-text="purse.remain"></span>
    </v-row>
  </v-card>
  </div>
</template>
<script>
import store from '@/store'
import { mapState } from 'vuex'
export default {
  data: () => ({
    wallets: [],
  }),
  computed: {
    ...mapState({
      purses: state => state.basket.purses, 
    })
  },
  methods: {
    purseUse (purse) {
      if (purse.use == true) {
        store.dispatch('cal_purse', purse)
      } else {
        store.dispatch('dont_use_purse', purse)
        store.dispatch('refresh_balance')
        for (const purse of this.purses) {
          if (purse.use) {
            store.dispatch('cal_purse', purse)
          }
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.v-input--selection-controls{
  margin: 0;
}
</style>
