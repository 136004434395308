<template>
<div>
   <v-radio-group @change="way_payment" v-model="radioGroup">
    <!-- <v-card class="pa-2 d-flex align-center card mb-2" height="50">
        <v-row class="d-flex align-center justify-space-between">
        <v-col cols="8" class="pa-2">
          <v-radio value="1" label="پرداخت آنلاین"></v-radio>
        </v-col>
        <v-col class="pa-2">
          <v-img class="mr-auto ml-2" width="30" src="@/assets/icon/credit-card3.svg"></v-img>
        </v-col>
      </v-row>
    </v-card> -->
    <v-card flat class="pa-2 card d-flex align-center" height="50">
        <v-row class="d-flex align-center justify-space-between">

        <v-col cols="8" class="pa-2">
          <v-radio value="2" label="پرداخت درب منزل"></v-radio>
        </v-col>
        <v-col class="pa-2">
          <v-img class="mr-auto ml-1" width="30" src="@/assets/icon/pos-terminal.svg"></v-img>
        </v-col>

        </v-row>
    </v-card>
   </v-radio-group>
</div>
</template>
<style lang="scss" scoped>
.v-input--selection-controls{
  margin-top: 0;
}
</style>
<script>
import store from '@/store'
  export default {
    data: () => ({
        radioGroup: 1,
    }),
    methods: {
      way_payment () {
        store.dispatch('way_pay', this.radioGroup)
      }
    }
  }
</script>